<template>
  <div>
    <div v-show="menuData.visible" :style="{left:menuData.left+'px',top:menuData.top+'px'}" class="menu">
      <div class="contextmenu__item"
           @click="checkEvent('edit')">
        编辑 &nbsp;<i class="el-icon-edit"></i>
      </div>
      <div class="contextmenu__item"
           @click="checkEvent('log')">
        日志 &nbsp;<i class="el-icon-document"></i>
      </div>
<!--      <div class="contextmenu__item"-->
<!--           @click="checkEvent('del')">删除&nbsp;<i class="el-icon-close"></i>-->
<!--      </div>-->
<!--      <div class="contextmenu__item"-->
<!--           @click="checkEvent('copy')">复制&nbsp;<i class="el-icon-document-copy"></i>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Contextmenu",
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo'])
  },
  props: {
    menuData: {
      type: Object,
      default() {
        return {
          visible: false,
          top: 0,
          left: 0
        }
      }
    }
  },
  watch: {
    menuData: {
      deep: true,
      handler() {
        // this.initList()
      }
    }
  },
  data() {
    return {
      menuVisible: false,
      // is_debug: false
    }
  },
  methods: {
    closeMenu() {
      this.menuData.visible = false;
    },
    checkEvent(val) {
      this.$emit('change', val)
      this.menuData.visible = false;
    },
  },
  created() {
    // this.is_debug = process.env.NODE_ENV === 'development'
    // console.log('process', process.env.NODE_ENV)
  }
}
</script>

<style scoped>

.contextmenu__item {
  display: block;
  line-height: 34px;
  text-align: left;
  padding-left: 10%;
}

.contextmenu__item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.menu {
  position: absolute;
  background-color: #fff;
  width: 220px;
  /*height: 106px;*/
  font-size: 14px;
  color: #444040;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  white-space: nowrap;
  z-index: 1000;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contextmenu__item:hover {
  cursor: pointer;
  background: #ff3176;
  border-color: #ff3176;
  color: #fff;
}
</style>
