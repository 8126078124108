<template>
  <!--   table列表     -->
  <div class="default-table">
    <ApeTable ref="apeTable"
              :data="dataList"
              :columns="[]"
              :loading="loadingStatus"
              :pagingData="pagingData"
              @row-contextmenu="openMenu"
              @switchPaging="switchPaging"
              @changeTableSort="changeTableSort"
              highlight-current-row
              border>
      <el-table-column
          slot="second-column"
          width="64"
          label="序号" align="center" fixed="left">
        <template slot-scope="scope">
          <span>{{ offset + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="group_id" label="组别" min-width="100" align="center" show-overflow-tooltip
                       slot="first-column">
        <template slot-scope="scope">
          <span>{{ scope.row.group_name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="红人昵称" min-width="120" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <artist-nickname :info="scope.row"/>
        </template>
      </el-table-column>
      <el-table-column prop="executor" label="AE" min-width="100" align="center" show-overflow-tooltip/>
      <el-table-column prop="platform_code" label="平台" min-width="100" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.platform_name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="brand" label="品牌" min-width="100" align="center" show-overflow-tooltip/>
      <el-table-column prop="product_title" label="产品名称" min-width="140" header-align="center" align="left"
                       show-overflow-tooltip/>
      <el-table-column prop="business_type" label="商务类型" min-width="80" align="center" sortable="custom">
        <template slot-scope="scope">
          <span>{{ scope.row.business_type_alias }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="coa_mode" label="合作形式" min-width="80" align="center"/>
      <el-table-column prop="discount" label="优惠/福利" min-width="120" header-align="center" align="left"
                       show-overflow-tooltip/>
      <el-table-column prop="report" label="报备" min-width="100" align="center" show-overflow-tooltip/>
      <el-table-column prop="status" label="状态" min-width="80" align="center" sortable="custom">
        <template slot-scope="scope">
          <span>{{ scope.row.status_alias }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="release_date" label="发布时间" min-width="120" align="center" sortable="custom"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.status === 'delay' ? scope.row.last_dates : scope.row.release_date }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="theme_content" label="内容主题" min-width="200" header-align="center" align="left"
                       show-overflow-tooltip/>
      <el-table-column prop="rate_progress" label="执行进度" min-width="80" align="center" fixed="right">
        <template slot-scope="scope">
          <el-link type="primary" @click="execStep(scope.row)">{{ scope.row.rate_progress }}</el-link>
        </template>
      </el-table-column>
    </ApeTable>

    <!--    右键菜单-->
    <Contextmenu :menu-data="menuData" @change="handleCmd"/>
  </div>
</template>

<script>
import ApeTable from "@/components/ApeTable";
import { numberFormat } from "@/utils/utils";
import Contextmenu from './Contextmenu'
import { mapGetters } from "vuex";

export default {
  name: "BusinessTable",
  components: {
    ApeTable, Contextmenu
  },
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo'])
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          ym: '',
          year: null,
          month: null,
          nickname: null,
          artist_id: null,
          done_status: null,
        }
      }
    }
  },
  watch: {
    'searchCondition.artist_id'(val, oldVal) {
      if (val !== oldVal) {
        // console.log('change artist')
        this.initList()
      }
    },
    'searchCondition.ym'(val, oldVal) {
      if (val !== oldVal) {
        // console.log('change year')
        this.initList()
      }
    },
    'searchCondition.release_month'(val, oldVal) {
      if (val !== oldVal) {
        // console.log('change year')
        this.initList()
      }
    },
    'searchCondition.done_status'(val, oldVal) {
      if (val !== oldVal) {
        // console.log('change year')
        this.initList()
      }
    },
  },
  data() {
    return {
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      orderSort: { release_date: 'asc', id: 'desc' },//默认排序规则
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 20
      },
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0,
      },
      currentRow: {},
      currentColumn: {}
    }
  },
  methods: {
    rateFormat(value) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    moneyFormat(value) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    numFormat(value) { // 数值 格式化
      return numberFormat(value, 0, '.', ',', 'round')
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.initList()
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.done_status) {
        condition.done_status = this.searchCondition.done_status
      }

      if (this.searchCondition.release_month) {
        condition.release_month = this.searchCondition.release_month
      }

      if (this.searchCondition.ym) {
        // console.log('ym',this.searchCondition.ym)
        let ym = this.searchCondition.ym
        let ymArr = ym.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0);
        let daycount = day.getDate();//最后一天

        // this.searchCondition.time_value = [ym + '-01', ym + '-' + daycount]
        condition.start_time = ym + '-01'
        condition.end_time = ym + '-' + daycount
      }

      return condition
    },
    async initList(type) {
      //
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      //筛选添加同步给父组件
      this.$emit('currentSearch', searchCondition)
      this.dataList = []
      let { list, pages } = await this.$api.getBusinessPlanList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      // this.offset = pages.offset
      this.loadingStatus = false
    },
    handleCmd(cmd) {
      this.$emit('handleCmd', cmd, this.currentRow)
    },
    openMenu(row, column, e) {
      this.currentRow = row
      this.currentColumn = column
      if (this.userPermissions.indexOf('business_manage_create') !== -1) {
        // const offsetWidth = this.$el.offsetWidth; // container width
        e.preventDefault();//阻止元素发生默认的行为

        const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
        const offsetTop = this.$el.getBoundingClientRect().top; // container margin left
        this.menuData = {
          visible: true,
          left: e.clientX - offsetLeft,
          top: e.clientY - offsetTop
        }
        document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      }
    },
    //右键菜单：设置是否月考核
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false;
        document.removeEventListener('click', this.closeMenu)
      }
    },
    execStep(row) {
      this.$emit('execStep', row)
    }
  }
}
</script>

<style scoped>

</style>
